import React from "react"
import * as styles from "./Building.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

const data = [
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Easyfill_1b8347e70f.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/column_startup_90ade6477e.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/CARMA_9737d35b6b.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/curckii_664eb79eea.png",
  },
]

const Building = () => {
  return (
    <div className={styles.Trust}>
      <Container>
        <div className={styles.border}>
          <p className={styles.grow}>
            Building Tomorrow’s <span>Silicon Valley Innovators</span>
          </p>
          <Row className={`${styles.rowGap}`}>
            {data.map((item, index) => (
              <div className={styles.logo}>
                <img src={item.logos} alt={`Client logo ${index + 1}`} />
              </div>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Building
