// extracted by mini-css-extract-plugin
export var Trust = "Stories-module--Trust--a6b75";
export var arrowIcon = "Stories-module--arrowIcon--bf252";
export var arrowImage = "Stories-module--arrowImage--ddc62";
export var arrowLink = "Stories-module--arrowLink--01cb3";
export var btn = "Stories-module--btn--ce6cb";
export var btn2 = "Stories-module--btn2--6fda6";
export var caseImages = "Stories-module--caseImages--75143";
export var clientImgs = "Stories-module--clientImgs--7c12b";
export var content = "Stories-module--content--90a64";
export var headSearch = "Stories-module--headSearch--af69a";
export var heading = "Stories-module--heading--e0abc";
export var logoImages = "Stories-module--logoImages--65e57";
export var logos = "Stories-module--logos--c7b77";
export var rowGap = "Stories-module--rowGap--c59a6";
export var talkBtn = "Stories-module--talkBtn--23de5";