// extracted by mini-css-extract-plugin
export var SliderWidth = "Journey-module--SliderWidth--56a73";
export var Trust = "Journey-module--Trust--1d70b";
export var arrowPoint = "Journey-module--arrowPoint--e5a8d";
export var buton = "Journey-module--buton--ff5ff";
export var cardContent = "Journey-module--cardContent--fd3b0";
export var cards = "Journey-module--cards--4a689";
export var heading = "Journey-module--heading--37613";
export var iconContainer = "Journey-module--iconContainer--2ebec";
export var iconContainerLeft = "Journey-module--iconContainerLeft--23b42";
export var indDec = "Journey-module--indDec--8f0a1";
export var points = "Journey-module--points--d179b";
export var portfolioArrowIcon = "Journey-module--portfolioArrowIcon--e974d";
export var portfolioArrowIconCover = "Journey-module--portfolioArrowIconCover--6ef40";
export var portfolioArrowRightIconCover = "Journey-module--portfolioArrowRightIconCover--68fa3";
export var sheildImgTop = "Journey-module--sheildImgTop--6b222";
export var shieldTopBannerImg = "Journey-module--shieldTopBannerImg--60257";
export var skill = "Journey-module--skill--bd4d2";
export var slideWrapper = "Journey-module--slideWrapper--b6eeb";
export var text = "Journey-module--text--7942a";