// extracted by mini-css-extract-plugin
export var arrowAnimation = "Models-module--arrowAnimation--8d8b4";
export var blackButton = "Models-module--blackButton--f2e4a";
export var blueButton = "Models-module--blueButton--ba903";
export var card = "Models-module--card--28fda";
export var colGap = "Models-module--colGap--67591";
export var description = "Models-module--description--f2940";
export var heading = "Models-module--heading--376af";
export var newBtn = "Models-module--newBtn--453cc";
export var newBtn2 = "Models-module--newBtn2--fd891";
export var progress = "Models-module--progress--bf1fc";
export var progressSec = "Models-module--progressSec--99cb7";
export var subHeading = "Models-module--subHeading--02908";