import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Qickly.module.scss"

const cardData = [
  {
    subHeading: "2-4 Weeks",
    heading: "For A Prototype",
    description:
      "Develop a clickable prototype to pitch your idea to investors and gather early feedback from users.",
    textColor: "black",
    button: "Get Started",
    link: "/contact-us/",
  },
  {
    subHeading: "2-3 Months",
    heading: "For An  MVP",
    description:
      "Launch your Minimum Viable Product (MVP) to collect insights from early adopters and identify key areas for improvement.",
    textColor: "white",
    button: "Explore More",
    link: "/contact-us/",
  },
  {
    subHeading: "1 Day-2 Weeks",
    heading: "For New Features",
    description:
      "Enjoy rapid updates and feature launches, allowing you to enhance your product without waiting for major releases.",
    button: "Need a Fix",
    textColor: "black",
    link: "/software-project-rescue/",
  },
]

const Qickly = () => {
  return (
    <div className={styles.progressSec}>
      <Container>
        <div className={styles.progress}>
          <h2 className={styles.heading}>
            How Quickly You Can <span>Launch a Product with Invozone</span>
          </h2>
          <p className={styles.subDescription}>
            While timelines vary based on your product's complexity, here’s what
            you can expect
          </p>
          <Row className="gap-30">
            {cardData.map((item, index) => (
              <Col
                xl={4}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={`${styles.colGap} d-flex`}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]}`}>
                <h3
                    className={`${styles.subHeading} ${
                      index === 1 ? styles.blue : styles[item.textColor]
                    }`}
                  >
                    {item.subHeading}
                  </h3>
                  <p
                    className={`${styles.heading} ${
                      index === 1 ? styles.blue : styles[item.textColor]
                    }`}
                  >
                    {item.heading}
                  </p>
                  <p
                    className={`${styles.description} ${
                      styles[item.textColor]
                    }`}
                  >
                    {item.description}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className={styles.concerns}>
          <Row className="justify-content-between align-items-center">
            <h2>Estimate a Prototype or Get a Product Development Roadmap</h2>

            <div className={styles.cardFooter}>
              <Link
                to={"/contact-us/"}
                className={styles.newBtn}
                style={{ color: "white" }}
              >
                Schedule A Call
              </Link>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Qickly
