import React from "react"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Startups.module.scss"

const cardData = [
  {
    title: "Startup Advisory Services",
    imgage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/startup_advisory_b2757964c2.json",
  },
  {
    title: "Strategic Tech Collaboration",
    imgage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/stragergic_4d83aab886.json",
  },
  {
    title: "Agile & Lean Startup Teams",
    imgage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/agile_and_lean_startup_5e061423ea.json",
  },
  {
    title: "Quality Centered Engineering",
    imgage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/quality_centered_engineering_f2cd5ca4db.json",
  },
  {
    title: "Fractional CTO’s/ CPO’s",
    imgage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/fractional_cto_4ac67c3395.json",
  },
  {
    title: "Project Management",
    imgage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/project_management_b80f688f83.json",
  },
]

function Steps() {
  return (
    <div className={styles.steps}>
      <Container>
        <h2>
          <span>InvoZone & Startups:</span>
          <br />
          The Saga Of Success & Triumph
        </h2>

        <Row>
          {cardData?.map((item, i) => (
            <Col lg={4} className={`${styles.calPad} d-flex`} key={i}>
              <div className={styles.cards}>
              <lottie-player
                    autoplay
                    loop
                    src={item?.imgage}
                    style={{ height: "60px", width: "60px", margin: "auto" }}
                  />
                <h3>
                  {i === 0 ? (
                    <>
                      Startup <span style={{ color: "rgba(34, 118, 255, 1)" }}>Advisory</span>
                      <br />
                      Services
                    </>
                  ) : i === 1 ? (
                    <>
                      Strategic <span style={{ color: "rgba(34, 118, 255, 1)" }}>Tech</span>
                      <br />
                      Collaboration
                    </>
                  ) : i === 2 ? (
                    <>
                      Agile &{" "}
                      <span style={{ color: "rgba(34, 118, 255, 1)" }}>
                        {" "}
                        Lean <br /> Startup
                      </span>
                      Teams
                    </>
                  ) : i === 3 ? (
                    <>
                      Quality
                      <span style={{ color: "rgba(34, 118, 255, 1)" }}> Centered</span>
                      <br />
                      Engineering
                    </>
                  ) : i === 4 ? (
                    <>
                      Fractional   <br />
                      <span style={{ color: "rgba(34, 118, 255, 1)" }}>CTO’s/ CPO’s</span>
                    
                    </>
                  ) : i === 5 ? (
                    <>
                      Project <span style={{ color: "rgba(34, 118, 255, 1)" }}>Management</span>
                      <br />
                    </>
                  ) : (
                    item?.title
                  )}
                </h3>
                <p>{item?.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Steps
