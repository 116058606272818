import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData, custome }) => {
  // const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={`${styles.banner} ${custome ? styles.customeBanner : ""}`}>
      <Container>
        <Row className="align-items-center">
          <Col xl={6} lg={12} md={12}>
            <div className={styles.heading}>
              <h1
                className={`${styles.mainTitle} ${
                  custome ? styles.customeHead : ""
                }`}
              >
                {custome
                  ? "Custom Software Development Services"
                  : "Software Development For Startups"}
              </h1>
              <p
                className={`${styles.bannerHeading} ${
                  custome ? styles.customeBanner : ""
                }`}
              >
                {custome ? (
                  <>
                    Accelerate Your Business With{" "}
                    <span className={styles.spanColor}>
                      Custom Software Solution
                    </span>
                  </>
                ) : (
                  <>
                    Innovate, <span className={styles.spanColor}>Ideate</span>{" "}
                    Accelerate
                  </>
                )}
              </p>
              <p className={styles.description}>
                {custome
                  ? "Off the shelf or pre-made software slowing you down? Every business is unique and needs custom software solutions to run daily operations and achieve business goals."
                  : "Got a product idea that excites you? Invozone is here to bring your vision to life, helping you realize its full market potential, no matter your budget or timeline."}
              </p>
            </div>
            <div className={styles.btns}>
            <Link to={"/contact-us/"}>
                <div className={`${styles.blueButton} blueButton`}>
                  {strapiData?.buttons[0] && <p>Got An Idea</p>}
                </div>
              </Link>

              <Link to={"/contact-us/"}>
                <div className={`${styles.blackButton} blackButton`}>
                  {strapiData?.buttons[0] && <p>Assemble Team</p>}
                </div>
              </Link>
            </div>
          </Col>
          <Col xl={6} lg={12} md={12} >
            <div>
              <img
              className={styles.bnrImg}
                src={
                  custome
                    ? "https://invozone-backend.s3.us-east-1.amazonaws.com/ICON_CUSTOM_PNG_1_6c64a39c02.webp"
                    : "https://invozone-backend.s3.us-east-1.amazonaws.com/Image_101_66c4dbd5ff.webp"
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
      />
    </div>
  )
}

export default Banner
