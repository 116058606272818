import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Models.module.scss"

const Models = ({ strapiData }) => {
  return (
    <div className={styles.progressSec}>
      <Container>
        <div className={styles.progress}>
          <h2 className={styles.heading}>
            Scale Faster Will Our <span>Flexible Engagement Models</span>
          </h2>

          <Row className="gap-30">
            {strapiData?.cards?.map((item, index) => (
              <Col
                xl={4}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={`${styles.colGap} d-flex`}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]} cardHoverEffect`}>
                  <img src={item?.image1[0]?.localFile?.publicURL} alt="tick" />
                  <h3 className={styles.subHeading}>{item.title}</h3>
                  <p
                    className={`${styles.description} ${
                      styles[item.textColor]
                    }`}
                  >
                    {item.subTitle}
                  </p>

                  <div className="buttonContainer">
                    <Link to={"/contact-us/"}>
                      <div className={`blueButton`}>
                        <p>Learn More</p>
                      </div>
                    </Link>
                    <Link to={"/contact-us/"}>
                      <div className={`blackButton`}>
                        <p>Learn More</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Models
