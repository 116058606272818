// extracted by mini-css-extract-plugin
export var banner = "Banner-module--banner--fc230";
export var bannerHeading = "Banner-module--bannerHeading--728e0";
export var blackButton = "Banner-module--blackButton--af419";
export var blueButton = "Banner-module--blueButton--22412";
export var bnrImg = "Banner-module--bnrImg--6de17";
export var btns = "Banner-module--btns--63e98";
export var curve = "Banner-module--curve--32256";
export var customeBanner = "Banner-module--customeBanner--62d5f";
export var customeHead = "Banner-module--customeHead--1ecc3";
export var description = "Banner-module--description--5292f";
export var heading = "Banner-module--heading--bb395";
export var mainTitle = "Banner-module--mainTitle--16de6";
export var spanColor = "Banner-module--spanColor--0de0a";