import React from "react"
import * as styles from "./Journey.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"
// import "./Clients.scss"
import Slider from "react-slick"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/right_f5df90e3bd.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/left_ccefba5791.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const HorizonMobile = ({ strapiData, projectEngRTest }) => {
  const data = [
    {
      name: "Pre-Seed",
      skills: [
        "Nuruting initial ideas into viable businesses.",
        "Guiding product development.",
        "A strong foundation for startup success.",
      ],
    },
    {
      name: "Seed",
      skills: [
        "Refining product-market fit.",
        "Providing mentorship and resources for growth.",
        "Establishing groundwork for future success.",
      ],
    },
    {
      name: "Series A",
      skills: [
        "Scaling operations  & expanding theme.",
        "Connecting with Series A Investors.",
        "Growth for the next phase.",
      ],
    },
    {
      name: "Series B",
      skills: [
        "Rapidly scaling and expanding product offerings.",
        "Providing strategic support for growth initiatives.",
        "Fueling growth for market dominance.",
      ],
    },
    {
      name: "Series C",
      skills: [
        "Streamlined Process Consultancy.",
        "Diverse Product and Engineering Teams.",
        "Balancing growth objectives.",
      ],
    },
    {
      name: "Growth Acquisition",
      skills: [
        "Technology partner for startups with expanded reach.",
        "Accelerated growth through acquisitions.",
        "Targeted marketing to capture new markets.",
      ],
    },
  ]

  const settings = {
    infinite: false,
    autoplay: false,
    slidesToShow: 2,
    // centerMode: true,
    centerPadding: "0px",
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: false,
          // dotsClass: "clients-dots",
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: false,
          // dotsClass: "clients-dots",
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <h2 className={styles.heading}>
            <span>We Connect With Startups</span> At Every Step Of Their Journey
          </h2>
          <Row className="align-items-center clientSlider">
            <Slider {...settings} className={styles.SliderWidth}>
              {data?.map((el, i) => (
                <div key={i} className={styles.slideWrapper}>
                  <div
                    className={`${styles.cards} ${
                      i % 2 === 0 ? styles.greyCard : styles.redCard
                    } ${styles.cardHover}`}
                  >
                    <div className={styles.cardContent}>
                      <span>0{i + 1}</span>
                      <h3>{el?.name}</h3>
                    </div>
                    <div className={styles.points}>
                      {el.skills &&
                        el.skills.map((skill, eIdx) => (
                          <div key={eIdx} className={styles.arrowPoint}>
                            <img
                              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_f2783a36be.png"
                              decoding="async"
                              loading="lazy"
                              alt=""
                            />
                            <p className={`${styles.skill}`}>{skill}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default HorizonMobile
