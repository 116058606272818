// extracted by mini-css-extract-plugin
export var SliderWidth = "HorizonMobile-module--SliderWidth--d9552";
export var Trust = "HorizonMobile-module--Trust--97be2";
export var arrowPoint = "HorizonMobile-module--arrowPoint--0ab7d";
export var buton = "HorizonMobile-module--buton--9c5fb";
export var cards = "HorizonMobile-module--cards--030ba";
export var heading = "HorizonMobile-module--heading--c323f";
export var image2 = "HorizonMobile-module--image2--a9415";
export var indDec = "HorizonMobile-module--indDec--70e9b";
export var points = "HorizonMobile-module--points--5defa";
export var sheildImgTop = "HorizonMobile-module--sheildImgTop--1ea7b";
export var shieldTopBannerImg = "HorizonMobile-module--shieldTopBannerImg--ea0b8";
export var skill = "HorizonMobile-module--skill--95e78";
export var text = "HorizonMobile-module--text--0adca";